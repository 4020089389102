import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import tabla from '../../../assets/tabla.json';
import paisesExpo from '../../../assets/paisesExpo.json';
import $impo from '../../../assets/$impo.json';
import $expo from '../../../assets/$expo.json';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-calculadora',
  templateUrl: './calculadora.component.html',
  styleUrls: ['./calculadora.component.css']
})
export class CalculadoraComponent implements OnInit {

  volumen;
  operacion;
  origen;
  destino;
  peso;
  zona;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup:FormGroup;
  fourFormGroup:FormGroup;

  addF = 15;
  addC = 15;
  addA = 30;
  total;

  impoMayorista=[
    {zona:1, tarifa:3.85 },
    {zona:2, tarifa:4.68 },
    {zona:3, tarifa:8.92 },
    {zona:4, tarifa:9.05 },
    {zona:5, tarifa:9.14 },
    {zona:6, tarifa:9.79 },
  
    ]

  paises = tabla;
  paisesExpo = paisesExpo;
  impo = $impo;
  expo = $expo;
  factura=false;
  clasif =false;
  asesor =false;
  mail;


  constructor(private _formBuilder: FormBuilder, public auth:AuthService, private router:Router) { }

  ngOnInit() {




  }

  calcularVolumen(a,h,p) {
    let v =  Math.round( (a * h * p)/5000);

      this.volumen =v;
      console.log(this.volumen);
      
  

    

  }
  
  getZona(z){
  this.zona=z;
  }

  calcular(){

    this.total = 0;
    // Exportacion
    if(this.operacion == "expo") {

     
      if(this.volumen > this.peso || !this.peso) {
          if(this.volumen > 50) { 
            alert('Para cotizar exportaciones sueperiores a 50 KG contactate con nosotros.')
          }

        let importe = this.expo.filter((peso) =>{ return peso.peso == this.volumen} )
        let z = this.zona;

            this.total =  (importe[0][`${z}`]  + (importe[0][`${z}`] * 1));

            console.log('Expo Volumen > Peso');

        }else {

          if(this.peso > 50) { 
            alert('Para cotizar exportaciones sueperiores a 50 KG contactate con nosotros.')
          }
          let importe = this.expo.filter((peso) =>{ return peso.peso == this.peso}  )
          let z = this.zona;
        
        
        
          
          this.total =  (importe[0][`${z}`]  + (importe[0][`${z}`] * 1));
          console.log('Expo Peso > Volumen');
        }



    }
    // Importacion
    else {

   
   
          if(this.volumen > this.peso || !this.peso) {
              if(this.volumen > 31.5){
                let importe = this.impoMayorista.filter((zona) =>{ return zona.zona == this.zona} )
                let z = this.zona;
                console.log('Mayorista');
                this.total = this.volumen * (importe[0]['tarifa']+ (importe[0]['tarifa']*1))
        
              }    

          let importe = this.impo.filter((peso) =>{ return peso.peso == this.volumen} )
          let z = this.zona;
              this.total =  (importe[0][`${z}`]  + (importe[0][`${z}`] * 1));


          }else {

            if(this.peso > 31.5){
              let importe = this.impoMayorista.filter((zona) =>{ return zona.zona == this.zona} )
              let z = this.zona;
              console.log('Mayorista');
              this.total = this.volumen * (importe[0]['tarifa']+ (importe[0]['tarifa']*1))
      
            }


            let importe = this.impo.filter((peso) =>{ return peso.peso == this.peso}  )
            let z = this.zona;
         
            this.total =  (importe[0][`${z}`]  + (importe[0][`${z}`] * 1));
            console.log(this.total);
          }

        }

     this.factura=false;
     this.clasif =false;
     this.asesor =false;

  }

  adicionalF(){
    if(this.factura){
      this.total += this.addF

    }else{
      this.total -= this.addF;
    }
 

  }

  adicionalC(){
    if(this.clasif){
      this.total += this.addC

    }else{
      this.total -= this.addC;
    }
 

  }

  adicionalA(){
    if(this.asesor){
      this.total += this.addA

    }else{
      this.total -= this.addA;
    }
 

  }


  login(){
    if(!this.mail){
      alert('Completa tu email')
    }
    this.auth.longLinkMail(this.mail)

  }


  guardarProforma() {
    let data = {
      volumen: this.volumen,
      operacion:  this.operacion,
      origen:  this.origen,
      destino:  this.destino,
      peso:  this.peso,
      zona:  this.zona,
      total: this.total,

    }

    localStorage.setItem('proforma',JSON.stringify(data));
    this.router.navigate(['/proforma'])
    
  }




}
