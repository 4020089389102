import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public uid;
  constructor( private db:AngularFireAuth, private router:Router) {

    this.getAuthStateObserver().subscribe((data) => {
      if(data) {
        this.uid = data.uid;
        console.log(data);

      }
    
    })

   }

  longLinkMail(email) {

    let actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: 'https://clickandcourier.com/login',
      // This must be true.
      handleCodeInApp: true,
     
    
    };

    return this.db.auth.sendSignInLinkToEmail(email,actionCodeSettings).then(() => {
      window.localStorage.setItem('emailForSignIn', email);
    }).catch((err) => alert(err))
  }

  autenticar(){
    
// Confirm the link is a sign-in with email link.
if (this.db.auth.isSignInWithEmailLink(window.location.href)) {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  var email = window.localStorage.getItem('emailForSignIn');
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt('Please provide your email for confirmation');
  }
  // The client SDK will parse the code from the link for you.
  this.db.auth.signInWithEmailLink(email, window.location.href)
    .then(function(result) {
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
    })
    .catch(function(error) {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
    });
}
  }



  public confirmSignIn(email: string, url: string): Promise<any> {
    return this.db.auth.signInWithEmailLink(email, url);
  }


  public getAuthStateObserver(): Observable<any> {
    return this.db.authState;
  }

  public signOut() {

    localStorage.removeItem('emailForSignIn');
    localStorage.removeItem('proforma');
    
    this.router.navigate(['login'])
    this.uid = null;
    return this.db.auth.signOut();
  }


}


