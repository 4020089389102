import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-proforma',
  templateUrl: './proforma.component.html',
  styleUrls: ['./proforma.component.css']
})
export class ProformaComponent implements OnInit {

  public proforma;
  constructor(private auth:AuthService) {
    this.proforma = JSON.parse(localStorage.getItem('proforma'))
    console.log(this.proforma.operacion);
   }

  ngOnInit() {

    window.scrollTo(0,0)
  }

}
