import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './compartido/home/home.component';
import { RegistroComponent } from './user/registro/registro.component';
import { LoginComponent } from './user/login/login.component';
import { PerfilComponent } from './user/perfil/perfil.component';
import { CalculadoraComponent } from './compartido/calculadora/calculadora.component';
import { BlogComponent } from './compartido/blog/blog.component';
import { ProformaComponent } from './compartido/proforma/proforma.component';







const ROUTES: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'registro', component: RegistroComponent },
    { path: 'login', component: LoginComponent },

    { path: 'perfil/:id', component: PerfilComponent },

    { path: 'calculadora', component: CalculadoraComponent },

    { path: 'proforma', component: ProformaComponent },


    { path: 'blog', component: BlogComponent },

    { path: '**', component: HomeComponent },

];


export const ROUTING = RouterModule.forRoot(ROUTES, {useHash: false});