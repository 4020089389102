import { Injectable } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor( private db:AngularFirestore) {
    
   }


  registrar(uid,data) {


    
   return this.db.collection('users').doc(uid).set(data)
  }

  getUsuario(uid) {
    return this.db.collection('users').doc(`${uid}`).snapshotChanges();
  }
}
