import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  user = null;
  constructor( private auth:AuthService, private firebase:FirebaseService) {

    console.log(this.auth.uid);

    this.firebase.getUsuario(this.auth.uid).subscribe((data) => {
      this.user = data.payload.data();
    })
   }

  ngOnInit() {


  if(this.user = null) { 
    let data ={
      email:localStorage.getItem('emailForSignIn')
     }
    this.firebase.registrar(this.auth.uid, data).then((data) => {
      console.log(data);
    })

  }


  }

}
