import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error;
  url;
  email;
  constructor(private db:AuthService, private route:ActivatedRoute, private router:Router, private firebase:FirebaseService) { }

  ngOnInit() {
    this.email = localStorage.getItem('emailForSignIn');
    this.url = this.router.url;
    console.log(this.url);

    if(this.email) {
      this.confirmSignIn();
    }
  }

  confirmSignIn() {

    this.db.confirmSignIn(this.email, this.url)
    .then(
      () => {
        this.router.navigate(['/home'])
      }
    )
    .catch(
      error => {
        this.error = error;
        alert(this.error)
      }
     )
  }


  enviarCorreo() {
    if(!this.email || this.email == '') {
      alert('Por favor completa el email')
    }
    this.db.longLinkMail(this.email).then(() => alert('Ingresa con el link que recibiste en tu correo'))
  }

}
