import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistroComponent } from './registro/registro.component';
import { MaterialModule } from '../material/material.module';
import { LoginComponent } from './login/login.component';
import { ROUTING } from '../app.routes';
import { PerfilComponent } from './perfil/perfil.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [RegistroComponent, LoginComponent, PerfilComponent],
  imports: [
    CommonModule,
    ROUTING,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class UserModule { }
