import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(public auth:AuthService ) { }

  ngOnInit() {

    

    let prevScrollpos = window.pageYOffset;
    window.onscroll = () => {
     let currentScrollPos = window.pageYOffset;
      if (prevScrollpos >= currentScrollPos) {
      document.getElementById("nav").style.position = "relative";
      } else {
      document.getElementById("nav").style.position = "fixed";
   
      
      }
      // prevScrollpos = currentScrollPos;
      }
  }

}
