import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './compartido/navbar/navbar.component';
import { MaterialModule } from './material/material.module';
import { HomeComponent } from './compartido/home/home.component';
import { NosotrosComponent } from './compartido/home/nosotros/nosotros.component';
import { FooterComponent } from './compartido/footer/footer.component';
import { ROUTING } from './app.routes';
import { UserModule } from './user/user.module';
import { CalculadoraComponent } from './compartido/calculadora/calculadora.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlogComponent } from './compartido/blog/blog.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import { ProformaComponent } from './compartido/proforma/proforma.component';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    NosotrosComponent,
    FooterComponent,
    CalculadoraComponent,
    BlogComponent,
    ProformaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MaterialModule,
    ROUTING,
    UserModule,
    ReactiveFormsModule,
    FormsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
